import styled from 'styled-components';

const Wrapper = styled.section`
  background: var(--gold-3);
  padding: var(--gap-2) var(--padding) var(--wave-offset-padding) var(--padding);
  margin-bottom: var(--wave-offset-negative-margin);
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 680px) {
    padding: var(--padding) var(--padding) var(--wave-offset-padding)
      var(--padding);
  }
`;

const Container = styled.div`
  width: 800px;
  padding: var(--gap-2);
  border: 4px solid var(--gold-2);
  background: var(--background-white);

  @media screen and (max-width: 680px) {
    padding: var(--gap-4);
  }

  & > div {
    font-family: 'Bookman', 'Palatino', -apple-system-ui-serif, ui-serif,
      'Georgia', 'URW Bookman L', serif;

    & a {
      text-decoration: underline;
      color: var(--gold-2);
    }

    & li,
    ol {
      list-style-type: none !important;
      list-style-position: inside;
    }

    & h1 {
      margin-bottom: 1.5em;
    }

    & h2,
    h3,
    hr,
    h4 {
      margin-top: 1.5em;
    }

    & hr {
      height: 1px;
      background-color: var(--gold-2);
      border: none;
    }

    & h2,
    h3,
    h4 {
      margin-bottom: 1em;
      line-height: 1.3;
    }

    & p {
      line-height: 1.6;
      margin-bottom: 1em;
    }
  }
`;

export const Document = ({ children }) => (
  <Wrapper>
    <Container>{children}</Container>
  </Wrapper>
);
