import { graphql } from 'gatsby';
import { useEffect } from 'react';
import { StructuredText } from 'react-datocms';
import { PageWrapper } from '../components/layout/PageWrapper';
import { Document } from '../components/layout/sections/legal/DocumentLayout';
import { isSSR } from '../utils/misc';

const CookiePolicy = ({ data: { datoCmsDocumentiLegali }, pageContext }) => {
  useEffect(() => {
    // Inject Cookiebot declaration
    if (!isSSR) {
      const cookiebotCookieDeclarationScript = document.createElement('script');
      cookiebotCookieDeclarationScript.src =
        'https://consent.cookiebot.com/6a304120-4374-4177-8e65-f027ca759394/cd.js';
      cookiebotCookieDeclarationScript.type = 'text/javascript';
      cookiebotCookieDeclarationScript.async = true;

      const cookiebotWrapperEl = document.getElementById(
        'cookieBotDeclaration'
      );

      if (cookiebotWrapperEl) {
        cookiebotWrapperEl.appendChild(cookiebotCookieDeclarationScript);
      }
    }
  }, []);

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle="Privacy & Cookie Policy"
      seoDescription="Privacy & Cookie Policy - La Rosa dei gusti"
    >
      <Document>
        <div>
          <StructuredText
            data={datoCmsDocumentiLegali.documentBody}
            renderBlock={() => (
              // eslint-disable-next-line no-underscore-dangle
              <div id="cookieBotDeclaration" />
            )}
          />
        </div>
      </Document>
    </PageWrapper>
  );
};

export default CookiePolicy;

export const query = graphql`
  {
    datoCmsDocumentiLegali {
      documentBody {
        value
        blocks {
          id: originalId
          divInject
        }
      }
    }
  }
`;
